<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.banner") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.pages"
                v-model="formModel.status"
                :items="statuses.pages.banner"
                :label="$t('FORMS.status')"
                item-value="key"
              >
                <template v-slot:selection="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
                <template v-slot:item="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
              </v-select>
            </v-col>
            <v-spacer></v-spacer>  
            <v-col cols="12" sm="6" md="6">
              <v-checkbox
                class="mt-4"
                v-model="formModel.in_admin_lists"
                :label="$t('FORM_INPUT_NAMES.in_admin_lists')"
              ></v-checkbox>
            </v-col>  
          </v-row> 

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :label="$t('FORM_INPUT_NAMES.name')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="formModel.type"
                :items="bannerTypes"
                :label="$t('FORM_INPUT_NAMES.type')"
                item-value="id"
                :rules="bannerTypeRules"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="8" md="8">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].url"
                :label="$t('FORM_INPUT_NAMES.url')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.url']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.url'] = ''
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-select
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .target
                "
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .target || 'self'
                "
                :items="targets"
                :label="$t('FORMS.target')"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menu_dp_1"
                v-model="menu_dp_1"
                :close-on-content-click="false"
                :return-value.sync="formModel.valid_from"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formModel.valid_from"
                    :label="$t('FORM_INPUT_NAMES.valid_from')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    max-width="200"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formModel.valid_from"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu_dp_1 = false">{{
                    $t("FORMS.cancel")
                  }}</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu_dp_1.save(formModel.valid_from)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
              <v-icon
                color="primary"
                small
                @click="formModel.valid_from = null"
              >
                flaticon2-delete
              </v-icon>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menu_dp_2"
                v-model="menu_dp_2"
                :close-on-content-click="false"
                :return-value.sync="formModel.valid_to"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formModel.valid_to"
                    :label="$t('FORM_INPUT_NAMES.valid_to')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    max-width="200"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="formModel.valid_to" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu_dp_2 = false">{{
                    $t("FORMS.cancel")
                  }}</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu_dp_2.save(formModel.valid_to)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
              <v-icon color="primary" small @click="formModel.valid_to = null">
                flaticon2-delete
              </v-icon>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="formModel.banner_client_id"
                :items="bannerClientCollection"
                :label="$t('FORMS.banner_client')"
                item-value="id"
                item-text="name"
                @change="changeBannerClient()"
                :rules="bannerClientRules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="formModel.banner_campaign_id"
                :items="bannerCampaignList"
                :label="$t('FORMS.banner_campaign')"
                item-value="id"
                item-text="name"
                clearable="true"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="desktop_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .desktop_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [],
                  selectButtonText: 'FORM_INPUT_NAMES.desktop_image',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="mobile_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .mobile_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [],
                  selectButtonText: 'FORM_INPUT_NAMES.mobile_image',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Banners";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = ["url", "name"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "mobile_image",
  "desktop_image",
  "target",
];

export const INITIAL_CUSTOM_FIELDS = {
  // name: "",
  // email: "",
  // favicon: "",
  // logo: "",
  // selectedLanguages: [],
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  name: "",
  type: null,
  translations: {},
  custom_fields: INITIAL_CUSTOM_FIELDS,
  attachments: [],
  banner_campaign_id: null,
  banner_client_id: null,
  valid_from: null,
  valid_to: null,
  in_admin_lists: 1,
});

export default {
  name: "PageForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      bannerTypeRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],

      bannerClientRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },
      targets: [
        { key: "self", value: "Self" },
        { key: "blank", value: "Blank" },
      ],

      bannerCampaignList: [],
      menu_dp_1: false, //Datetime picke (valid from)
      menu_dp_2: false, //Datetime picke (valid to)
    };
  },
  computed: {
    ...mapGetters(["bannerCampaignCollection", "bannerClientCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.banner");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },

    computedLangItems() {
      return this.languages.map((item) => {
        return { label: item.name, value: item.lang };
      });
    },
    bannerTypes() {
      let config_banner_types = this.$store.getters.types.banner.banner_type;
      let bannerTypes = [];
      config_banner_types.forEach((item) => {
        bannerTypes.push({
          id: item.value,
          text: this.$t("BANNER_TYPES." + item.value),
        });
      });
      return bannerTypes;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {  
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());

          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              if (!data.custom_fields) {
                data.custom_fields = INITIAL_CUSTOM_FIELDS;
              }
              if (data.valid_from && !isNaN(Date.parse(data.valid_from))) {
                data.valid_from = data.valid_from.substring(0, 10);
              }
              if (data.valid_to && !isNaN(Date.parse(data.valid_to))) {
                data.valid_to = data.valid_to.substring(0, 10);
              }
              this.formModel = Object.assign({}, data);

              this.setTranslatedAttributes();
              this.fillBannerCampaignsVSelect(); 
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
              this.$refs.form.resetValidation();
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();
          this.fillBannerCampaignsVSelect(); 
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchBannerCampaign", "fetchBannerClient"]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            let isTranslated = false;
            this.translatedAttributes.forEach((attribute) => {
              if (
                model.translations[langCode] &&
                model.translations[langCode][attribute] &&
                model.translations[langCode][attribute] != ""
              ) {
                isTranslated = true;
              }
            });
            if (isTranslated) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });

          if (Object.keys(translations).length == 0) {
            translations[this.selectedLocale.lang] =
              model.translations[this.selectedLocale.lang];
          }
          model.translations = Object.assign({}, translations);
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
    
    changeBannerClient() {
      this.fillBannerCampaignsVSelect();
    },
    fillBannerCampaignsVSelect() {
        this.bannerCampaignList = [];
        if(this.formModel.banner_client_id) {
            this.bannerCampaignCollection.forEach((item) => {
                if (item.banner_client_id === this.formModel.banner_client_id) {
                    this.bannerCampaignList.push(item);
                }
            });
        }
    },
  },

  mounted() {
    //this.fetchBannerType();
    this.setTranslatedAttributes();
    this.fetchBannerCampaign();
    this.fetchBannerClient();
  },
};
</script>
